ul.compositions,
ul.programs {
  li {
    cursor: pointer;
  }
}

ul.compositions {
}

ul.image_list {
  display: table;
  width: 100%;
  > li {
    @include hover-lighten;
    overflow: auto;
    display: table-row;
    padding-left: $half-margin;
    padding-right: $half-margin;
    > div {
      border-bottom: 1px solid $border-light;
      padding-top: $half-margin!important;
      padding-bottom: $half-margin;
      display: table-cell;
      vertical-align: middle;
    }
  }
  .image {
    width: 100px;
    padding-left: $half-margin;
  }
  .title {
    border-bottom: 1px solid $border-light;
    display: table-cell;
    padding-left: 1rem;
    padding-right: $half-margin;
    .composer {
      font-size: .9rem;
    }
  }
  .short_description {
    font-style: italic;
    opacity: 70%;
  }
  .icons {
    color: $white-fade-medium;
    font-weight: normal;
    margin-left: 1rem;
    font-size: 1rem;
    display: inline-block;
  }
  h4 {
    color: $red;
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.4rem;
    a:hover {
      color: $red;
    }
  }

  ul.genres {
    display: none;
    text-align: right;
    align-self: center;
    li {
      display: inline-block;
      height: 20px;
      background: url(../images/tag_right.png) no-repeat right bottom;
      padding: 0;
      padding-right: 10px;
      margin-right: .5rem;
      &:hover {
        opacity: .8;
      }
      a {
        margin: 0;
        float: left;
        padding-left: 1rem;
        height: 20px!important;
        line-height: 1.3rem;
        color: $dark-gray;
        font-family: $secondary-font-family;
        font-size: .7rem;
        text-transform: uppercase;
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
        background: url(../images/tag_left.png) no-repeat left top;

      }
    }
  }

  @include breakpoint(medium up) {
    > li {
      padding: .5rem 0 .5rem!important;
    }
    ul.genres {
      display: block;
    }
    .image, .title {
    }
    .image {
      height: 80px;
      width: 140px!important;
    }
    .title {
      .composer {
        font-size: 1.1rem;
      }
      h4 {
        font-size: 1.3rem;
        line-height: 1.2rem;
      }
      .icons {
        line-height: 1.4rem;
        vertical-align: top;
      }
    }
  }
}

ul.image_grid {
   padding-top: 2rem;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5em;

  > li {
    // width: 264px;
    // height: 218px;
    // margin-right: 1.5rem;

  }
  .image {
    // height: 150px;
    // max-height: 150px;
    aspect-ratio: 48 / 27; // 1920 / 1080
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .title {
    text-align: center;
    margin-top: .5rem;
    .composer {
      font-size: .8rem;
    }
    .icons {
      margin-left: .25rem;
      vertical-align: middle;
    }
  }
  h4 {
    color: $red;
    margin: 0;
    font-size: 1rem;
  }

  ul.genres {
    display: none;
  }
}
@media print, screen and (min-width: 540px) {
  ul.image_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media print, screen and (min-width: 800px) {
  ul.image_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media print, screen and (min-width: 1024px) {
  ul.image_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
ul.image_grid_float {
  padding-top: 2rem;
  > li {
    float: left;
    width: 264px;
    height: 218px;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
  }
  .image {
    height: 150px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .title {
    text-align: center;
    margin-top: .5rem;
    .composer {
      font-size: .8rem;
    }
    .icons {
      margin-left: .25rem;
      vertical-align: middle;
    }
  }
  h4 {
    color: $red;
    margin: 0;
    font-size: 1rem;
  }

  ul.genres {
    display: none;
  }
}
