body.ReactModal__Body--open {
  overflow: hidden;
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0, 0.75);
  display: flex;
  justify-content: center;
  align-content: center;
}

.modal_content {
  max-width: 50rem;
  width: 100%;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  background: $spco-body;
  overflow: auto;
  webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 1.5rem 1rem;
  box-shadow: 0px 0px 5px 5px rgba($spco-body, 0.3);
  .close_button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.search_modal {
  input.search_box {
    background: #333 url(../images/icons/search-solid.png) no-repeat left center;
    background-position: 10px center;
    background-size: 1rem;
    padding-left: 2rem;
  }
  .no_results {
    margin-top: 1rem;
  }

  .title {
    h4 {
      font-size: 0.9rem!important;
    }
    .composer {
      font-size: 0.8rem!important;
    }
  }
  li.person .title a {
    font-size: 0.9rem!important;
  }
  ul.image_list {
    > li {
      padding: .2rem 0 .2rem!important;
      @include focus-within-border;
    }
  }
  @include breakpoint(medium up) {
    li.person .title a {
      font-size: 1.1rem!important;
    }
    .title {
      h4 {
        font-size: 1.1rem!important;
      }
      .composer {
        font-size: .9rem!important;
      }
    }
    ul.image_list {
      > li {
        padding: .3rem 0 .3rem!important;
      }
    }
  }
}
.search_results {
  // h5 {
  //   font-family: $secondary-font-family;
  //   text-transform: uppercase;
  //   font-size: .9rem;
  //   margin: .5rem 0 1rem;
  // }
  .image_list {
    .image {
      height: 30px;
      width: 60px!important;
    }
  }
  li.person .title a {
    display: block;
    font-weight: bold;
    &:hover {
      color: $red;
    }
  }
  .show_more {
    margin-top: .5rem;
  }
  .search_section {
    margin-bottom: 1rem;
    &.show_three {
      .image_list {
        li:nth-child(n+4) {
          display: none;
        }
      }
    }
  }
}