$red: #d83b20;
$spco-body: #222222;
$spcoHighlightGray: #2a2a2a;
$spco-white: #fefefe;
$white-fade: rgba($spco-white, .7);
$white-fade-medium: rgba($spco-white, .5);
$white-fade-more: rgba($spco-white, .3);

$field-background: #333;
$field-background-focus: lighten($field-background, 10%);
$field-background-invalid: #62251D;

$border-light: rgba($spco-white, .12);
$border-light-medium: rgba($spco-white, .25);
$border-light-strong: rgba($spco-white, .45);
$border-light-gray: #e0e0e0;

$secondary-font-family: 'Raleway', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$plain-font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$text-gray: #888;
$main-margin: 1rem;
$half-margin: .5rem;