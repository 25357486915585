@mixin small-caps {
  font-size: .8rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.1rem;
}

@mixin thick-caps {
  font-size: .8rem;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.1rem;
}

@mixin strong-link-item {
  color: $light-gray;
  @include small-caps;
  font-weight: 900!important;
  letter-spacing: .05rem;
}
@mixin image-replacement($width, $height) {
  height: $height;
  width: $width;
  text-indent: 101%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin hover-lighten {
  &:hover {
    background-color: rgba(100,100,100,0.1);
  }
}
@mixin focus-within-border {
  &:focus-within {
    outline: 2px solid $border-light-strong!important;
    border: none;
    a:focus {
      border: none;
      outline: none;
    }
  }
}

@mixin paragraph-text {
  font-family: $secondary-font-family;
  font-size: 1rem;
  line-height: 1.7rem;
  color: $white-fade;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}